<template>
	<div class="container-fluid">
		<div class="row">
			<div
				class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3"
				v-for="notice in notices"
				:key="notice.id"
			>
				<div class="card w-100">
					<router-link
						:to="{
							name: 'Notice',
							params: { lang: lang, console: console, slug: notice.slug }
						}"
					>
						<img :src="notice.image" class="card-img-top" :alt="notice.title" />
					</router-link>
					<div class="card-body">
						<h5 class="card-title mb-0 text-center title">
							{{ notice.title }}
						</h5>
					</div>
					<div class="card-footer d-flex justify-content-between">
						<small class="text-muted">{{ notice.created_at }}</small>
						<small class="text-muted"
							>{{ notice.reviews }} {{ $t('comments') }}</small
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			slug: this.$route.params.teamSlug,
			notices: []
		}
	},
	computed: {
		...mapGetters(['lang', 'console'])
	},
	mounted() {
		this.$store.dispatch('SET_MENU_PRIMARY_USER', 'home')
		this.fetchData()
	},
	methods: {
		fetchData() {
			const lang = this.$route.params.lang
			const console = this.$route.params.console
			const slug = this.$route.params.playerSlug
			const path = `${lang}/console/${console}/player/${slug}/notices`
			this.$axios.get(path).then(response => {
				this.notices = response.data.data
			})
		}
	}
}
</script>
